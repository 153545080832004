<template>
  <div class="guide-content">
    <div v-if="!isBeThree" class="cp-guide-header">
      <div
        v-if="site.groups.length || site.scenic.length"
        class="cp-header__info-item cp-header__noshrink"
      >
        <cp-site-dropdown :value="currentSite" v-bind="site"></cp-site-dropdown>
      </div>
      <el-dropdown
        class="cp-header__user cp-header__info-item cp-header__noshrink"
        @command="userMenu"
      >
        <span class="el-dropdown-link cp-header__info-btn">
          {{ userInfo.name || "用户"
          }}<i class="cp-icon cp-header__more-icon">&#xea56;</i>
        </span>
        <el-dropdown-menu slot="dropdown" class="cp-header-dropdown-menu">
          <el-dropdown-item
            v-if="localDeployment && !isDaqsoftOs"
            command="changePassword"
          >
            修改密码
          </el-dropdown-item>
          <el-dropdown-item command="backindex">
            系统主页
          </el-dropdown-item>
          <el-dropdown-item command="systemSettings">
            系统设置
          </el-dropdown-item>
          <el-dropdown-item
            v-if="localDeployment && !isDaqsoftOs"
            command="quit"
          >
            退出
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!--  使用动态组件切换  -->
    <component
      :is="skinname"
      v-if="checkedImgItem"
      :level-list="levelList"
      :center-info="centerInfo"
      :center-logo="centerLogo"
      :isshow="isshow"
      :level-flag="levelFlag"
      :screen-width="screenWidth.toString()"
      :screen-height="screenHeight.toString()"
      @refresh="refresh"
    ></component>
    <change-password
      v-if="localDeployment && ischangepwd"
      :changepwd="ischangepwd"
      @closeDiag="closeDiag"
    ></change-password>
    <div v-if="showDialog" class="shadow">
      <div class="dialog">
        <div class="dialog_left">
          <div v-for="(i, index) in dialogLeft" :key="index">
            <div
              class="left_Item"
              :class="{ activeItem: changeLeftItem === index }"
              @click="changItem(index)"
            >
              {{ i.name }}
            </div>
          </div>
        </div>
        <div class="dialog_right">
          <div class="right_close">
            <i class="el-icon-close" @click="closeDialog()"></i>
          </div>
          <div
            style="border: 0;border-top: 1px dashed #ffffff;opacity: 0.1;"
          ></div>
          <div class="right_content">
            <el-row v-if="changeLeftItem === 0" :gutter="20">
              <el-col
                v-for="(i, index) in imgData"
                :key="index"
                :span="8"
                @click.native="chooseItem(i.id)"
              >
                <div
                  class="itemImg"
                  :class="{ activeImg: checkedImgItemTemp === i.id }"
                >
                  <img :src="i.src" alt="image" />
                </div>
                <div
                  class="itemName"
                  :class="{ activeName: checkedImgItemTemp === i.id }"
                >
                  {{ i.name }}
                </div>
              </el-col>
            </el-row>
            <el-row v-if="false" :gutter="20">
              <el-col
                v-for="(i, index) in btnData"
                :key="index"
                :span="6"
                @click.native="chooseBtn(i.id)"
              >
                <div
                  class="btnName"
                  :class="{ activeBtn: checkedBtn === i.id }"
                >
                  {{ i.name }}
                </div>
              </el-col>
            </el-row>
          </div>
          <div
            style="border: 0;border-top: 1px dashed #ffffff;opacity: 0.1;"
          ></div>
          <div class="right_footer">
            <div class="cancle" @click="closeDialog()">
              取 消
            </div>
            <div class="sure" @click="sure()">
              确 定
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CpSiteDropdown from "@/components/header/src/site-dropdown";
import ChangePassword from "@/components/header/src/changePassword.vue";
import scenicManagementPlatformConfig from "scenicManagementPlatformConfig";
import { mapGetters } from "vuex";
import comm from "@/utils/comm.js";
// import * as waterMarks from './json/watermarks.json';
export default {
  name: "Guide",
  components: {
    CpSiteDropdown,
    ChangePassword
  },
  // eslint-disable-next-line max-lines-per-function
  data() {
    return {
      projectName: scenicManagementPlatformConfig.projectName,
      // 是否在大旗OS内打开
      isDaqsoftOs: comm.isDaqsoftOs(),
      isBeThree: false,
      len: 0,
      showerObj: null,
      listObj: null,
      r: null,
      cR: 0,
      ccR: 0,
      timer: 0,
      scrollTime: 0,
      // 菜单是否平铺
      levelFlag: false,
      // 层级菜单
      levelList: [],
      site: {
        // 集团站点
        groups: [],
        // 景区站点
        scenic: [],
        // 默认站点
        defaultCompany: {}
      },
      // 是否修改密码
      ischangepwd: false,
      // 是否是本地部署
      localDeployment: false,
      centerCollect: false,
      centerInfo: {},
      centerLogo: {},
      rotation3D: null,
      screenWidth: "",
      screenHeight: "",
      shownum: false,
      wartingTimes: 6,
      showDialog: false,
      changeLeftItem: 0,
      dialogLeft: [
        { id: 0, name: "智能换肤" }

        /* ,
        // 正式版上线屏蔽
        { id: 1,
          name: '布局设置' }*/
      ],
      imgData: [
        {
          id: "lsjd",
          name: "经典宫格",
          component: "wgs-guide-second",
          src: require("../../assets/images/wgs-second/wgs.jpg")
        },
        {
          id: "kjzl",
          name: "科技蓝旋转",
          component: "jmg-guide",
          src: require("../../assets/images/color1.jpg")
        },
        {
          id: "lslt",
          name: "帘式立体",
          component: "xianhai-guide",
          src: require("../../assets/images/xianhai/pf.jpg")
        },
        {
          id: "sby",
          name: "青蓝旋转",
          component: "sby-guide",
          src: require("../../assets/images/sby/fengmian.png")
        }
        // 正式版上线屏蔽
        // { id: 2,
        //   name: '温暖橙黄色',
        //   component: 'wushan-guide',
        //   src: require('../../assets/images/color2.jpg') }
        // { id: 3,
        //   name: '科技蓝色',
        //   component: 'wgs-guide',
        //   src: require('../../assets/images/color3.jpg') }
        // { id: 2,
        //   name: '科技智能绿',
        //   src: require('../../assets/images/color3.jpg') }*/
      ],
      checkedImgItem: "",
      checkedImgItemTemp: null,
      btnData: [
        { id: 1, name: "1920*1080" },
        { id: 2, name: "2560*1080" },
        { id: 3, name: "3200*1080" },
        { id: 4, name: "3840*1080" }
      ],
      checkedBtn: 1,
      isshow: true
    };
  },
  computed: {
    ...mapGetters({
      token: "token",
      user: "user",
      siteId: "siteId"
    }),
    skinname() {
      const checedID = this.projectName === 'shiyuan' ? 'sby' : this.checkedImgItem;
      const skininfo = this.imgData.find(val => val.id === checedID);
      console.log("skininfo.component : ", skininfo.component);
      return skininfo ? skininfo.component : "";
    },
    userInfo() {
      return (this.user && this.user.employee) || {};
    },
    currentSite() {
      let site = this.site.scenic.find(
        item =>
          item.id === this.siteId && item.accountId === this.user.account.id
      );
      if (!site) {
        site = this.site.groups.find(
          item =>
            item.id === this.siteId && item.accountId === this.user.account.id
        );
        if (!site) {
          return null;
        }
      }
      return site;
    }
  },
  watch: {
    token(token) {
      // token变化必须重新获取
      if (token) {
        this.$store.dispatch("getUserInfo");
        this.getGuideTheme();
        this.getGuidePageConfig();
        this.getSiteList();
      }
    }
    // siteId(id) {
    //   id && this.changeSite(id);
    // }
  },
  beforeRouteEnter(to, from, next) {
    // 通过 `vm` 访问组件实例
    next(vm => {
      // 获取地址栏的token
      const token = sessionStorage.getItem("urlToken");
      vm.initToken(token, "enter");
    });
  },
  beforeRouteUpdate(to, from, next) {
    const token = sessionStorage.getItem("urlToken");
    this.initToken(token, "update");
    // 路由变化时，设置
    next();
  },
  beforeDestroy() {
    // console.log('destroy');
    if (this.rotation3D) {
      this.rotation3D.close();
      this.rotation3D = null;
    }
  },
  mounted() {
    if (window.scenicManagementPlatformConfig.isLocal) {
      // 本地部署项目
      this.localDeployment = true;
    } else {
      this.localDeployment = false;
    }
    const abc = sessionStorage.getItem("isthridSys");
    if (abc && Number(abc) === 1) {
      this.isBeThree = true;
    } else {
      this.isBeThree = false;
    }
    this.screenWidth = document.body.clientWidth;
    this.screenHeight = document.body.clientHeight;
    window.onresize = () =>
      (() => {
        // alert('页面加载');
        this.screenWidth = document.body.clientWidth;
        this.screenHeight = document.body.clientHeight;
        // this.showAnimation();
        // location.reload();
        // const levelList = JSON.parse(JSON.stringify(this.levelList));
        // this.levelList = [];
        this.isshow = false;
        setTimeout(() => {
          // this.levelList = levelList;
          this.isshow = true;
        }, 100);
      })();
  },
  methods: {
    // 查询主题皮肤配置
    getGuideTheme() {
      this.$api
        .getGuideTheme()
        .then(res => {
          if (res.code === 0) {
            if (res.data && res.data.themeSkin) {
              const themeInfo = this.imgData.find(
                v => v.id === res.data.themeSkin
              );
              if (themeInfo) {
                this.checkedImgItem = res.data.themeSkin;
                this.checkedBtn = this.btnData[0].id;
                this.checkedImgItemTemp = this.checkedImgItem;
              } else {
                this.setSkinDefaultInfo();
              }
            } else {
              this.setSkinDefaultInfo();
            }
          } else {
            this.$notice.operateError("获取皮肤失败", res.message || "");
          }
          return false;
        })
        .catch(err => {
          this.$notice.operateError("获取皮肤失败", err.message || "");
        });
    },
    // 设置默认皮肤
    setSkinDefaultInfo() {
      this.checkedImgItem =
        window.scenicManagementPlatformConfig.theme || this.imgData[0].id;
      this.checkedBtn = this.btnData[0].id;
      this.checkedImgItemTemp = this.checkedImgItem;
      this.setSkinInfo();
    },
    updateGuideTheme(themeSkin) {
      this.$api
        .updateGuideTheme({
          themeSkin
        })
        .then(res => {
          if (res.code === 0) {
            console.log(res.data);
          } else {
            this.$notice.operateError("设置皮肤失败", res.message || "");
          }
          return false;
        })
        .catch(err => {
          this.$notice.operateError("设置皮肤失败", err.message || "");
        });
    },
    refresh() {
      this.getGuidePageConfig();
    },
    // 获取永久存储的数据
    getSkinInfo() {
      if (this.user.account.id && this.user.umsCompany.id) {
        let skininfo = localStorage.getItem(
          `skininfo-${this.user.account.id}-${this.user.umsCompany.id}`
        );
        if (skininfo) {
          skininfo = JSON.parse(skininfo);
          if (this.imgData.find(v => v.id === skininfo.skin)) {
            this.checkedImgItem = skininfo.skin;
            this.checkedBtn = skininfo.resolving;
            this.checkedImgItemTemp = this.checkedImgItem;
            return;
          }
        }
        this.checkedImgItem = this.imgData[0].id;
        this.checkedBtn = this.btnData[0].id;
        this.checkedImgItemTemp = this.checkedImgItem;
        this.setSkinInfo();
      }
    },
    // 存储皮肤信息
    setSkinInfo() {
      this.updateGuideTheme(this.checkedImgItem);
    },
    chooseBtn(index) {
      this.checkedBtn = index;
    },
    chooseItem(index) {
      this.checkedImgItemTemp = index;
      // this.checkedImgItem = index;
    },
    sure() {
      if (
        !this.checkedImgItemTemp ||
        this.checkedImgItemTemp === this.checkedImgItem
      ) {
        this.checkedImgItemTemp = null;
      } else {
        this.checkedImgItem = this.checkedImgItemTemp;
      }
      this.showDialog = false;
      this.setSkinInfo();
    },
    closeDialog() {
      this.showDialog = false;
      this.changeLeftItem = 0;
      // this.getSkinInfo();
    },
    changItem(index) {
      this.changeLeftItem = index;
    },
    changeSkin() {
      this.showDialog = true;
    },
    handleAnimation(info) {
      console.log(info);
    },
    // 查询开关和平铺状态
    getGuidePageConfig() {
      // 先获取页面LOGO
      this.getGuidePageLogo();
      // 查询开关和平铺状态
      this.$api
        .guidePageConfig()
        .then(res => {
          if (res.code === 0) {
            // 开关和平铺状态
            if (res.data.openFlag) {
              this.levelFlag = res.data.levelFlag;
              if (res.data.levelFlag) {
                this.getGuidePageFlatList();
              } else {
                this.getGuidePageLevelList();
              }
              this.getGuidePageCenter();
            }
          } else {
            this.$notice.operateError("获取状态失败", res.message || "");
          }
          return false;
        })
        .catch(err => {
          this.$notice.operateError("获取状态失败", err.message || "");
        });
    },
    // 查询平铺显示-引导页数据(仅启用)
    getGuidePageFlatList() {
      const objs = {
        customBaseUrl: window.scenicManagementPlatformConfig.customBaseUrl || ""
      };
      this.$api
        .guidePageFlatList(objs)
        .then(res => {
          if (res.code === 0) {
            // 开关和平铺状态
            this.levelList = res.datas;
          } else {
            this.$notice.operateError(
              "获取平铺显示数据失败",
              res.message || ""
            );
          }
          return false;
        })
        .catch(err => {
          this.$notice.operateError("获取平铺显示数据失败", err.message || "");
        });
    },
    // 查询层级显示-引导页数据(仅启用)
    getGuidePageLevelList() {
      const objs = {
        customBaseUrl: window.scenicManagementPlatformConfig.customBaseUrl || ""
      };
      this.$api
        .guidePageLevelList(objs)
        .then(res => {
          if (res.code === 0) {
            // 开关和平铺状态
            this.levelList = res.datas;
          } else {
            this.$notice.operateError(
              "获取层级显示数据失败",
              res.message || ""
            );
          }
          return false;
        })
        .catch(err => {
          this.$notice.operateError("获取层级显示数据失败", err.message || "");
        });
    },
    getGuidePageCenter() {
      const objs = {
        customBaseUrl: window.scenicManagementPlatformConfig.customBaseUrl || ""
      };
      this.$api
        .guidePageCenter(objs)
        .then(res => {
          if (res.code === 0) {
            // 开关和平铺状态
            this.centerCollect = res.collect;
            this.centerInfo = res.data;
          } else {
            this.$notice.operateError("获取中心数据失败", res.message || "");
          }
          return false;
        })
        .catch(err => {
          this.$notice.operateError("获取中心数据失败", err.message || "");
        });
    },
    getGuidePageLogo() {
      this.$api
        .guidePageLogo()
        .then(res => {
          if (res.code === 0) {
            // 开关和平铺状态
            this.centerLogo = res.data;
            document.title = this.centerLogo.title;
          } else {
            this.$notice.operateError("获取LOGO失败", res.message || "");
          }
          return false;
        })
        .catch(err => {
          this.$notice.operateError("获取LOGO失败", err.message || "");
        });
    },
    // 加载动画
    showAnimation() {
      if (this.shownum) {
        return;
      }
      this.shownum = true;
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.rotation3D) {
            this.rotation3D.close();
            this.rotation3D = null;
          }
          // eslint-disable-next-line no-undef
          this.rotation3D = new Rotation3D({
            id: "#rotation3D",
            farScale: 0.4,
            // farScale: 1,
            // x半径压缩
            xRadius: 0,
            // y半径压缩
            yRadius: 260,
            // yRadius: 0,  //y半径压缩
            autoPlay: true,
            autoPlayDelay: 6000
          });
          this.shownum = false;
          this.rotation3D.goTo(2);
        }, 1000);
      });
    },
    /* eslint-disable */
    changeSite(id) {
      let site = this.site.scenic.find(
        item => item.id === id && item.accountId === this.user.account.id
      );
      if (!site) {
        site = this.site.groups.find(
          item => item.id === id && item.accountId === this.user.account.id
        );
        if (!site) {
          return;
        }
      }
      this.loading = true;

      // 切换站点，获取新的token
      this.$api
        .changeGroup({
          // authCode: this.$store.getters.authCode,
          accountId: site.accountId,
          vCode: site.vcode
        })
        .then(res => {
          console.log("res: ", res);
          this.loading = false;
          const tokenInfo = (res.data && res.data.token) || {};
          const token = tokenInfo.token;
          sessionStorage.setItem("urlToken", token);
          if (tokenInfo.token) {
            if (res.data.guidePageFlag) {
              // alert('494')
              // 跳转到导览页
              this.$router.push({
                path: `/guide`
              });
            } else {
              // 景区版
              if (site.versionType === 2) {
                // 跳转到首页
                this.$router.push({
                  path: `/index`
                });
              } else {
                // 集团，携带新token跳转到集团页面
                window.location.href = `${window.location.origin}/group/index#${tokenInfo.token}`;
              }
            }
          } else {
            this.$notice.operateError("切换站点失败", "获取token不存在");
          }
        })
        .catch(error => {
          this.loading = false;
          if (error.data) {
            this.$notice.operateError("切换站点失败", error.data.message);
          } else {
            // 判断http code
            this.$notice.operateError("切换站点失败", "后台异常");
          }
        });
    },
    userMenu(command) {
      switch (command) {
        case "userInfo":
          window.open("http://test.jkxds.com/#/business/account");
          break;
        case "quit":
          this.$confirm("即将退出系统, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
            .then(() => {
              this.$api
                .loginOut()
                .then(() => {
                  this.$store.commit("clear");
                  this.$store.commit("token", "");
                  setTimeout(() => {
                    if (this.localDeployment) {
                      // 本地部署
                      sessionStorage.removeItem("iframe");
                      top.location.href = `${window.location.protocol}//${window.location.host}/scenic/login`;
                    } else {
                      top.location.href =
                        sessionStorage.getItem("entrance") ||
                        window.scenicManagementPlatformConfig.entrance;
                    }
                  }, 200);
                })
                .catch(data => {
                  this.$notice.operateError("提示", `${data.message}`);
                  this.$store.commit("clear");
                  setTimeout(() => {
                    if (this.localDeployment) {
                      // 本地部署
                      sessionStorage.removeItem("iframe");
                      top.location.href = `${window.location.protocol}//${window.location.host}/scenic/login`;
                    } else {
                      top.location.href =
                        sessionStorage.getItem("entrance") ||
                        window.scenicManagementPlatformConfig.entrance;
                    }
                  }, 200);
                });
            })
            .catch(() => {
              // 取消
            });
          break;
        case "changePassword":
          this.ischangepwd = true;
          break;
        case "backindex":
          this.$router.push("/index");
          break;
        case "systemSettings":
          this.changeSkin();
          break;
        default:
          break;
      }
    },
    // 关闭修改密码框
    closeDiag(param) {
      this.ischangepwd = param;
    },

    /**
     * [初始化页面]
     * @param {[String]} token [传入的token]
     * @param {[String]} type [enter: 路由进入时，update: 路由更新时]
     * @returns
     */
    initToken(token, type) {
      // 只要刷新页面就必须重新获取（因为有可能获取失败，后期可以判断下）
      if (token && token !== this.token) {
        if (type === "update") {
          this.$store.commit("token", this.token);
          // 修复iframe与浏览器token不一致问题
          this.$nextTick(() => {
            const href = `${location.href.split("#")[0]}#${this.token}`;
            history.pushState(null, null, href);
          });
        } else {
          this.$store.commit("token", token);
        }
      } else if (this.token) {
        // 无token表示，使用存储的信息
        // this.$store.dispatch('getUserInfo');
        this.getGuideTheme();
        this.getGuidePageConfig();
        this.getSiteList();
      } else {
        this.$router.push({
          path: "/login"
        });
      }
    },
    getSiteList() {
      // 获取站点列表
      this.$api
        .getSiteList()
        .then(res => {
          const data = res.data;
          // console.log(data);
          this.site.groups = data.groups || [];
          this.site.scenic = data.scenic || [];

          if (data.defaultCompany) {
            this.site.defaultCompany = data.defaultCompany;
          } else {
            // TODO 获取配置的默认站点，访问站点
            const group = this.site.groups[0];
            if (group) {
              this.site.defaultCompany = group;
            }
          }
        })
        .catch(() => {
          this.loading = false;
          // 右下角提示改为ajax 403 统一处理
          // if (error.data) {
          //   this.$notice.operateError('获取用户的景区和集团列表失败', error.data.message);
          // } else {
          //   // 判断http code
          //   this.$notice.operateError('获取用户的景区和集团列表失败', '后台异常');
          // }
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.guide-content {
  width: 100%;
  height: 100%;
}
.shadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 9999; //这个地方只有设9999才能挡住右上角的按钮
  background: rgba(3, 9, 24, 0.9);
}
.dialog {
  display: flex;
  flex-direction: row;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: #081426;
  border: 1px solid #00ffff;
  border-radius: 5px;
  width: 800px;
  height: 500px;
  opacity: 0.8;
  cursor: pointer;
  .dialog_left {
    width: 116px;
    background-color: #0d192b;
    margin: 2px; //防止块元素把border的左上角和左下角顶没了
  }
  .dialog_right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .right_close {
      text-align: right;
      font-size: 14px;
      padding: 20px;
    }
    .right_content {
      height: calc(100% - 80px);
      padding: 20px;
      .imgContent {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
    .itemImg {
      border: 1px solid #064351;
      border-radius: 3px;
    }
    .activeImg {
      border: 1px solid #33ffff;
      border-radius: 3px;
    }
    .itemName {
      text-align: center;
      padding: 10px 0;
      color: #adc4cc;
      font-size: 14px;
      margin-bottom: 20px;
    }
    .activeName {
      text-align: center;
      padding: 10px 0;
      color: #00ffff;
      font-size: 14px;
      margin-bottom: 20px;
    }
    .btnName {
      text-align: center;
      padding: 20px 40px;
      margin-bottom: 20px;
      background-color: #0d2234;
      border-radius: 5px;
      border: solid 1px #00ace5;
      color: #adc4cc;
      font-size: 14px;
    }
    .activeBtn {
      text-align: center;
      padding: 20px 40px;
      margin-bottom: 20px;
      color: #33ffff;
      border-radius: 5px;
      border: 1px solid #33ffff;
      background-color: #071423;
    }
    .right_footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 20px 20px 20px 0;
      .cancle {
        background-color: #091221;
        border: 1px solid #33ffff;
        border-radius: 3px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        color: #33ffff;
        padding: 5px 12px;
        margin-right: 20px;
      }
      .sure {
        background-color: #00cccc;
        border: 1px solid #00cccc;
        border-radius: 3px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        color: #ffffff;
        padding: 5px 12px;
      }
    }
  }
}
//不能写到.dialog的class里面，否则不生效
.left_Item {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #adc4cc;
  text-align: center;
  padding: 20px 0;
}
.activeItem {
  color: #00ffff;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  text-align: center;
  padding: 20px 0;
  background-color: #081426;
}
/*右边切换按钮*/
.cp-guide-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: -5px;
  color: #869bbf;
  font-size: resize(16);
  z-index: 100;
  .cp-header__info-item:first-child:before {
    display: none;
  }
}
</style>
