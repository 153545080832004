var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "guide-content" },
    [
      !_vm.isBeThree
        ? _c(
            "div",
            { staticClass: "cp-guide-header" },
            [
              _vm.site.groups.length || _vm.site.scenic.length
                ? _c(
                    "div",
                    { staticClass: "cp-header__info-item cp-header__noshrink" },
                    [
                      _c(
                        "cp-site-dropdown",
                        _vm._b(
                          { attrs: { value: _vm.currentSite } },
                          "cp-site-dropdown",
                          _vm.site,
                          false
                        )
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-dropdown",
                {
                  staticClass:
                    "cp-header__user cp-header__info-item cp-header__noshrink",
                  on: { command: _vm.userMenu },
                },
                [
                  _c(
                    "span",
                    { staticClass: "el-dropdown-link cp-header__info-btn" },
                    [
                      _vm._v(" " + _vm._s(_vm.userInfo.name || "用户")),
                      _c("i", { staticClass: "cp-icon cp-header__more-icon" }, [
                        _vm._v(""),
                      ]),
                    ]
                  ),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "cp-header-dropdown-menu",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown",
                    },
                    [
                      _vm.localDeployment && !_vm.isDaqsoftOs
                        ? _c(
                            "el-dropdown-item",
                            { attrs: { command: "changePassword" } },
                            [_vm._v(" 修改密码 ")]
                          )
                        : _vm._e(),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "backindex" } },
                        [_vm._v(" 系统主页 ")]
                      ),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "systemSettings" } },
                        [_vm._v(" 系统设置 ")]
                      ),
                      _vm.localDeployment && !_vm.isDaqsoftOs
                        ? _c(
                            "el-dropdown-item",
                            { attrs: { command: "quit" } },
                            [_vm._v(" 退出 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.checkedImgItem
        ? _c(_vm.skinname, {
            tag: "component",
            attrs: {
              "level-list": _vm.levelList,
              "center-info": _vm.centerInfo,
              "center-logo": _vm.centerLogo,
              isshow: _vm.isshow,
              "level-flag": _vm.levelFlag,
              "screen-width": _vm.screenWidth.toString(),
              "screen-height": _vm.screenHeight.toString(),
            },
            on: { refresh: _vm.refresh },
          })
        : _vm._e(),
      _vm.localDeployment && _vm.ischangepwd
        ? _c("change-password", {
            attrs: { changepwd: _vm.ischangepwd },
            on: { closeDiag: _vm.closeDiag },
          })
        : _vm._e(),
      _vm.showDialog
        ? _c("div", { staticClass: "shadow" }, [
            _c("div", { staticClass: "dialog" }, [
              _c(
                "div",
                { staticClass: "dialog_left" },
                _vm._l(_vm.dialogLeft, function (i, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "div",
                      {
                        staticClass: "left_Item",
                        class: { activeItem: _vm.changeLeftItem === index },
                        on: {
                          click: function ($event) {
                            return _vm.changItem(index)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(i.name) + " ")]
                    ),
                  ])
                }),
                0
              ),
              _c("div", { staticClass: "dialog_right" }, [
                _c("div", { staticClass: "right_close" }, [
                  _c("i", {
                    staticClass: "el-icon-close",
                    on: {
                      click: function ($event) {
                        return _vm.closeDialog()
                      },
                    },
                  }),
                ]),
                _c("div", {
                  staticStyle: {
                    border: "0",
                    "border-top": "1px dashed #ffffff",
                    opacity: "0.1",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "right_content" },
                  [
                    _vm.changeLeftItem === 0
                      ? _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          _vm._l(_vm.imgData, function (i, index) {
                            return _c(
                              "el-col",
                              {
                                key: index,
                                attrs: { span: 8 },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.chooseItem(i.id)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "itemImg",
                                    class: {
                                      activeImg:
                                        _vm.checkedImgItemTemp === i.id,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: { src: i.src, alt: "image" },
                                    }),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "itemName",
                                    class: {
                                      activeName:
                                        _vm.checkedImgItemTemp === i.id,
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(i.name) + " ")]
                                ),
                              ]
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                    false
                      ? _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          _vm._l(_vm.btnData, function (i, index) {
                            return _c(
                              "el-col",
                              {
                                key: index,
                                attrs: { span: 6 },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.chooseBtn(i.id)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "btnName",
                                    class: {
                                      activeBtn: _vm.checkedBtn === i.id,
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(i.name) + " ")]
                                ),
                              ]
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c("div", {
                  staticStyle: {
                    border: "0",
                    "border-top": "1px dashed #ffffff",
                    opacity: "0.1",
                  },
                }),
                _c("div", { staticClass: "right_footer" }, [
                  _c(
                    "div",
                    {
                      staticClass: "cancle",
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_vm._v(" 取 消 ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "sure",
                      on: {
                        click: function ($event) {
                          return _vm.sure()
                        },
                      },
                    },
                    [_vm._v(" 确 定 ")]
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }